import React from "react";

import { PageProps } from "gatsby";
import { Ref } from "react";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useContext } from "react";
import { useLayoutEffect } from "react";

import ThemeContext from "@Js/Context/ThemeContext";

import Gutenberg from "@Js/Component/Gutenberg";

/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        content?: string;
    };
};

/**
 * @type NotFoundProps
 */
export type NotFoundProps = PageProps;

/**
 * @const NotFound
 */
const NotFound = (props: NotFoundProps, ref: Ref<HTMLDivElement>) => {
    const {
        setHeaderBackground,
        setIsLogoColorless,
        setIsMenuColorless
    } = useContext(ThemeContext);

    const data: WordpressPageData = useStaticQuery(graphql`
        query {
            wordpressPage(isNotFoundPage: { eq: true }) {
                content
            }
        }
    `);

    useLayoutEffect(() => {
        setHeaderBackground("#FFFFFF");

        setIsLogoColorless(false);
        setIsMenuColorless(false);
    }, []);

    return (
        <div ref={ ref }>
            { data.wordpressPage.content && (
                <Gutenberg content={ data.wordpressPage.content } />
            ) }
        </div>
    );
};

export default forwardRef(NotFound);
